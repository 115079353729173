import request from "../request";


// 添加图片
function addImg (data) {
  return request({
    url: "/center/imageInfo",
    method: "post",
    data,
  })
}
// 查询图片信息列表
function imgListInfo (query) {
  return request({
    url: '/center/imageInfo/list',
    method: 'get',
    params: query
  })
}
// 修改图片
function editImg (data) {
  return request({
    url: "/center/imageInfo/record",
    method: "post",
    data,
  })
}
// 首页获取详情
function getImgInfo (infoId) {
  return request({
    url: '/detail/imageInfo/' + infoId,
    method: 'get'
  })
}
// 个人中心
function userImgInfo (infoId) {
  return request({
    url: '/center/imageInfo/' + infoId,
    method: 'get',
  })
}




// 添加3d模型
function addModel3D (data) {
  return request({
    url: '/center/model3D',
    method: 'post',
    data: data,
  })
}
// 查看3d详情
function getModelInfo (model3DId) {
  return request({
    url: '/detail/model3D/' + model3DId,
    method: 'get'
  })
}
// 个人中心
function UserModelInfo (infoId) {
  return request({
    url: '/center/model3D/' + infoId,
    method: 'get'
  })
}

// 查询3d列表
function modelListInfo (query) {
  return request({
    url: '/center/model3D/list',
    method: 'get',
    params: query
  })
}
// 修改3d
function editModel (data) {
  return request({
    url: "/center/model3D/record",
    method: "post",
    data,
  })
}




// 添加全景图
function addPanorama (data) {
  return request({
    url: '/center/panorama',
    method: 'post',
    data: data,
  })
}
// 获取全景图详情
function getPanoramaInfo (panoramaId) {
  return request({
    url: '/detail/panorama/' + panoramaId,
    method: 'get'
  })
}
// 个人中心
function UserPanoramaInfo (panoramaId) {
  return request({
    url: '/center/panorama/' + panoramaId,
    method: 'get'
  })
}

// 查询全景图列表
function panoramaListInfo (query) {
  return request({
    url: '/center/panorama/list',
    method: 'get',
    params: query
  })
}

function editPanorama (data) {
  return request({
    url: '/center/panorama/record',
    method: 'post',
    data,
  })
}




// 添加断层扫描
function addTomography (data) {
  return request({
    url: '/center/tomography',
    method: 'post',
    data: data,
  })
}

// 查询断层扫描列表
function tomographyListInfo (query) {
  return request({
    url: '/center/tomography/list',
    method: 'get',
    params: query
  })
}
// 获取断层详情
function getTomographyInfo (tomographyId) {
  return request({
    url: '/detail/tomography/' + tomographyId,
    method: 'get'
  })
}
// 个人中心
function UserTomographyInfo (tomographyId) {
  return request({
    url: '/center/tomography/' + tomographyId,
    method: 'get'
  })
}

function editTomography (data) {
  return request({
    url: '/center/tomography/record',
    method: 'post',
    data,
  })
}




// /image/search/biologicalCategoryList
// /image/search/biologicalCategoryList
// 获取分类列表
function listBiologicalCategory (query) {
  return request({
    url: '/image/screen/category',
    method: 'get',
    params: query
  })
}

// 查询当前分类的所有上级分类
function parentListBiologicalCategory (id) {
  return request({
    url: 'image/biologicalCategory/parentList?currentId=' + id,
    method: 'get',
  })
}

// 查询生物分类详细
function getBiologicalCategory (biologicalCategoryId) {
  return request({
    url: '/center/biologicalCategory/' + biologicalCategoryId,
    method: 'get'
  })
}

// 新增生物分类
function addBiologicalCategory (data) {
  return request({
    url: '/center/biologicalCategory',
    method: 'post',
    data: data
  })
}

// function addBiologicalCategory(data) {
//     return request({
//         url: '/center/biologicalCategory',
//         method: 'post',
//         data: data
//     })
// }


// 修改生物分类
function updateBiologicalCategory (data) {
  return request({
    url: '/center/biologicalCategory',
    method: 'put',
    data: data
  })
}

// 删除生物分类
function delBiologicalCategory (biologicalCategoryId) {
  return request({
    url: '/center/biologicalCategory/' + biologicalCategoryId,
    method: 'delete'
  })
}

// /image/biologicalCategory/dataStatistics
// 显示数量
function dataStatistics () {
  return request({
    url: '/image/biologicalCategory/dataStatistics',
    method: 'get'
  })
}

function info_record () {
  return request({
    url: '/image/record/list?pageSize=20&pageNum=1',
    method: 'get'
  })
}

// 分页获取当前分类的下级分类
function getchildrenList (params) {
  return request({
    url: 'image/screen/childrenList',
    method: 'get',
    params,
  })
}


// 获取搜索列表
function getTemplate (params) {
  return request({
    url: '/image/info/list',
    method: 'get',
    params,
  })
}







export default {
  info_record,
  dataStatistics,
  // 图片
  addImg,
  editImg,
  getImgInfo,
  userImgInfo,
  imgListInfo,
  // 3d
  addModel3D,
  getModelInfo,
  modelListInfo,
  UserModelInfo,
  editModel,
  // 全景图
  addPanorama,
  panoramaListInfo,
  getPanoramaInfo,
  UserPanoramaInfo,
  editPanorama,
  // 断层扫描
  addTomography,
  tomographyListInfo,
  getTomographyInfo,
  editTomography,
  UserTomographyInfo,
  //分类
  parentListBiologicalCategory,
  listBiologicalCategory,
  getBiologicalCategory,
  addBiologicalCategory,
  updateBiologicalCategory,
  delBiologicalCategory,
  getchildrenList,
  //搜索
  getTemplate
}